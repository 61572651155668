import {Injectable} from '@angular/core';
import {IMenuService} from '../../../layouts/expose-out/service/menu.service';
import {ChildrenItem, Menu} from '../../../layouts/expose-out/model/menu';
import {TokenService} from '../token.service';
import {NAVIGATION} from '../../app.config';
import {USER_NAVIGATION} from '../../../user/user.config';
import {BRIDGE_NAVIGATION} from '../../../bridge/bridge.config';
import {SUBCONTRACTOR_NAVIGATION} from '../../../subcontractor/subcontractor.config';
import {ConfigService} from '../config.service';
import {ADMIN_MONITOR_NAVIGATION} from '../../../admin-monitor/admin-monitor.config';
import {REPORT_NAVIGATION} from '../../../report/report.config';
import {AppCommonMethods} from '../../app.common.methods';
import {
  ADMIN_MONITOR_MODULE,
  ADMIN_PANEL_MODULE,
  APP_SETTINGS_MODULE,
  BRIDGE_ISSUE,
  BRIDGE_LIST,
  BRIDGE_MODULE,
  BRIDGE_PROGRESS,
  BRIDGE_PROGRESS_NOT_REPORTED,
  BRIDGE_REPORT,
  BRIDGE_SPAN,
  DASHBOARD_MODULE,
  MONTHLY_REPORT,
  NEW_BRIDGE,
  RENEW_BRIDGE,
  RENEW_BRIDGE_LIST,
  REPORTS_MODULE,
  SUB_CONTRACTOR_LIST,
  SUB_CONTRACTOR_MODULE,
  SUB_CONTRACTOR_REGISTRATION,
  USER_LIST,
  USER_MODULE,
  USER_REGISTRATION
} from '../../ui.component.config';

@Injectable()
export class MenuService extends IMenuService {

  public constructor(private tokenService: TokenService, private configService: ConfigService, private appCommonMethod: AppCommonMethods) {
    super();
  }

  getMenuDataForTheUserContext(): Menu[] {
    const menuItems: Menu[] = [];
    menuItems.push(this.getDashBoardMenuItem());
    menuItems.push(this.getBridgeMenuItem());
    menuItems.push(this.getUserMenuItem());
    menuItems.push(this.getSubcontractorMenuItem());
    menuItems.push(this.getReportMenuItem());
    menuItems.push(this.getAdminMonitorMenuItem());
    menuItems.push(this.getAdminPanelMenuItem());
    menuItems.push(this.getAppSettingsMenuItem());
    menuItems.push(this.getAboutUsMenuItem());
    return menuItems;
  }

  getDashBoardMenuItem(): Menu {
    if (this.appCommonMethod.getUIModuleActive(DASHBOARD_MODULE)) {
      const menu: Menu = {
        state: NAVIGATION.DASHBOARD,
        name: 'Dashboard',
        type: 'link',
        icon: 'icon icon-basic-accelerator',
      };
      return menu;
    }
  }


  getBridgeMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(BRIDGE_MODULE)) {
      menu.state = NAVIGATION.BRIDGE;
      menu.name = 'Bridge';
      menu.type = 'sub';
      menu.icon = 'icon icon-basic-notebook';
      menu.children = [];

      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, NEW_BRIDGE)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.NEW_BRIDGE;
        item.name = 'New Bridge';
        menu.children.push(item);

      }

      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, RENEW_BRIDGE)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.RENEW;
        item.name = 'Renew Bridge';
        menu.children.push(item);
      }
      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, RENEW_BRIDGE_LIST)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.RENEW_LIST;
        item.name = 'Renew Bridge List';
        menu.children.push(item);
      }
      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_LIST)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.LIST;
        item.name = 'Bridge List';
        menu.children.push(item);
      }

      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_PROGRESS)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.PROGRESS;
        item.name = 'Bridge Progress';
        menu.children.push(item);

      }
      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_PROGRESS_NOT_REPORTED)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.PROGRESS_NOT_REPORTED;
        item.name = 'Progress Not Reported';
        menu.children.push(item);
      }
      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_ISSUE)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.ISSUE;
        item.name = 'Bridge Issue/ Extra work';
        menu.children.push(item);
      }
      if (this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_SPAN)) {
        const item: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        item.state = BRIDGE_NAVIGATION.SPAN_MASTER;
        item.name = 'Span Master';
        menu.children.push(item);
      }
    }
    return menu;
  }

  getUserMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(USER_MODULE)) {
      menu.state = NAVIGATION.USER;
      menu.name = 'User';
      menu.type = 'sub';
      menu.icon = 'icon icon-user';
      menu.children = [];

      if (this.appCommonMethod.getUIComponentActive(USER_MODULE, USER_REGISTRATION)) {
        const userRegistration: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        userRegistration.state = USER_NAVIGATION.REGISTRATION;
        userRegistration.name = 'User Registration';
        menu.children.push(userRegistration);
      }

      if (this.appCommonMethod.getUIComponentActive(USER_MODULE, USER_LIST)) {
        const userList: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        userList.state = USER_NAVIGATION.LIST;
        userList.name = 'User List';
        menu.children.push(userList);
      }
    }
    return menu;
  }

  getSubcontractorMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(SUB_CONTRACTOR_MODULE)) {
      menu.state = NAVIGATION.SUBCONTRACTOR;
      menu.name = 'Sub-Contractor';
      menu.type = 'sub';
      menu.icon = 'icon icon-people';
      menu.children = [];

      if (this.appCommonMethod.getUIComponentActive(SUB_CONTRACTOR_MODULE, SUB_CONTRACTOR_REGISTRATION)) {
        const subContractorRegistration: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        subContractorRegistration.state = SUBCONTRACTOR_NAVIGATION.REGISTRATION;
        subContractorRegistration.name = 'Sub-Contractor Registration';
        menu.children.push(subContractorRegistration);
      }
      if (this.appCommonMethod.getUIComponentActive(SUB_CONTRACTOR_MODULE, SUB_CONTRACTOR_LIST)) {
        const subContractorList: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        subContractorList.state = SUBCONTRACTOR_NAVIGATION.LIST;
        subContractorList.name = 'Sub-Contractor List';
        menu.children.push(subContractorList);
      }
    }
    return menu;
  }

  getReportMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(REPORTS_MODULE)) {
      menu.state = NAVIGATION.REPORT;
      menu.name = 'Reports';
      menu.type = 'sub';
      menu.icon = 'icon-chart';
      menu.children = [];

      if (this.appCommonMethod.getUIComponentActive(REPORTS_MODULE, BRIDGE_REPORT)) {
        const progressReport: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        progressReport.state = REPORT_NAVIGATION.BRIDGE_REPORT;
        progressReport.name = 'Bridge Reports';
        menu.children.push(progressReport);
      }
      if (this.appCommonMethod.getUIComponentActive(REPORTS_MODULE, MONTHLY_REPORT)) {
        const monthlyReport: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
        monthlyReport.state = REPORT_NAVIGATION.MONTHLY_REPORT;
        monthlyReport.name = 'Monthly Reports';
        menu.children.push(monthlyReport);
      }
    }
    return menu;
  }

  getAboutUsMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    menu.state = NAVIGATION.ABOUT_US;
    menu.name = 'About Us';
    menu.type = 'link';
    menu.icon = 'icon-info';
    return menu;
  }

  getAdminMonitorMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(ADMIN_MONITOR_MODULE)) {
      menu.state = NAVIGATION.ADMIN_MONITOR;
      menu.name = 'Admin-Monitor';
      menu.type = 'sub';
      menu.icon = 'icon icon-graph';
      menu.children = [];

      const monitorEndPoints: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      monitorEndPoints.state = ADMIN_MONITOR_NAVIGATION.MONITOR_END_POINTS;
      monitorEndPoints.name = 'Monitor-End-Points';
      menu.children.push(monitorEndPoints);

      const info: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      info.state = ADMIN_MONITOR_NAVIGATION.INFO;
      info.name = 'Information';
      menu.children.push(info);

      const health: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      health.state = ADMIN_MONITOR_NAVIGATION.HEALTH;
      health.name = 'Health';
      menu.children.push(health);

      const configProperties: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      configProperties.state = ADMIN_MONITOR_NAVIGATION.CONFIG_PROPERTIES;
      configProperties.name = 'Config-Properties';
      menu.children.push(configProperties);

      const metrics: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      metrics.state = ADMIN_MONITOR_NAVIGATION.METRICS;
      metrics.name = 'Metrics';
      menu.children.push(metrics);

      const auditEvents: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      auditEvents.state = ADMIN_MONITOR_NAVIGATION.AUDIT_EVENTS;
      auditEvents.name = 'Audit-Events';
      menu.children.push(auditEvents);

      const env: ChildrenItem = this.configService.getEmptyInstanceOfChildrenItem();
      env.state = ADMIN_MONITOR_NAVIGATION.ENV;
      env.name = 'Environment';
      menu.children.push(env);
    }
    return menu;

  }

  getAdminPanelMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(ADMIN_PANEL_MODULE)) {
      menu.state = NAVIGATION.ADMIN_PANEL;
      menu.name = 'Admin Panel';
      menu.type = 'link';
      menu.icon = 'icon-settings';
    }
    return menu;
  }

  getAppSettingsMenuItem(): Menu {
    const menu: Menu = this.configService.getEmptyInstanceOfMenuWithChildren();
    if (this.appCommonMethod.getUIModuleActive(APP_SETTINGS_MODULE)) {
      menu.state = NAVIGATION.APP_SETTINGS;
      menu.name = 'App Settings';
      menu.type = 'link';
      menu.icon = 'icon-equalizer';
    }
    return menu;
  }

  signOut() {
    this.tokenService.logoutAndClearToken();
  }

  afterSignOutRedirectURL(): string {
    return NAVIGATION.HOME;
  }
}
