import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {AppCommonMethods} from '../../../app.common.methods';
import {
  ADMIN_MONITOR_MODULE,
  AUDIT_EVENTS,
  CONFIG_PROPERTIES,
  ENV,
  HEALTH,
  INFO,
  METRICS,
  MONITOR_END_POINTS
} from '../../../ui.component.config';

@Injectable()
export class AdminMonitorAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService, private appCommonMethod: AppCommonMethods) {
  }

  canActivate(): boolean {
    const url: string = this._router.getCurrentNavigation().finalUrl.toString();
    let status = false;

    switch (url) {
      case FinalUrls.MONITOR_END_POINTS:
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, MONITOR_END_POINTS);
        break;

      case FinalUrls.INFO:
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, INFO);
        break;

      case FinalUrls.ENV:
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, ENV);
        break;

      case FinalUrls.METRICS:
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, METRICS);
        break;

      case FinalUrls.AUDIT_EVENTS :
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, AUDIT_EVENTS);
        break;

      case FinalUrls.HEALTH:
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, HEALTH);
        break;

      case FinalUrls.CONFIG_PROPERTIES:
        status = this.appCommonMethod.getUIComponentActive(ADMIN_MONITOR_MODULE, CONFIG_PROPERTIES);
        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }
}
