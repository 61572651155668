export const INDEX = 'index';
export const DASHBOARD_MODULE = 'Dashboard Module';
export const BRIDGE_MODULE = 'Bridge Module';
export const SUB_CONTRACTOR_MODULE = 'Sub Contractor Module';
export const USER_MODULE = 'User Module';
export const REPORTS_MODULE = 'Reports Module';
export const ADMIN_MONITOR_MODULE = 'Admin Monitor Module';
export const ADMIN_PANEL_MODULE = 'Admin Panel Module';
export const APP_SETTINGS_MODULE = 'App Settings Module';
export const NEW_BRIDGE = 'New Bridge';
export const RENEW_BRIDGE = 'Renew Bridge';
export const RENEW_BRIDGE_LIST = 'Renew Bridge List';
export const BRIDGE_LIST = 'Bridge List';
export const BRIDGE_PROGRESS = 'Bridge Progress';
export const BRIDGE_PROGRESS_NOT_REPORTED = 'Bridge Progress Not Reported';
export const BRIDGE_ISSUE = 'Bridge Issue';
export const BRIDGE_SPAN = 'Bridge Span';
export const SUB_CONTRACTOR_REGISTRATION = 'Sub Contractor Registration';
export const SUB_CONTRACTOR_LIST = 'Sub Contractor List';
export const USER_REGISTRATION = 'User Registration';
export const USER_LIST = 'User List';
export const BRIDGE_REPORT = 'Bridge Report'
export const MONTHLY_REPORT = 'Monthly Report'
export const MONITOR_END_POINTS = 'Monitor End Points';
export const INFO = 'Information';
export const ENV = 'Environment';
export const AUDIT_EVENTS = 'Audit Events';
export const HEALTH = 'Health';
export const METRICS = 'Metrics';
export const CONFIG_PROPERTIES = 'Config Properties';
export const DASHBOARD = 'Dashboard';
export const BTN = 'btn';


export enum NEW_BRIDGE_SECTIONS {
  GENERAL_SECTION = 'GENERAL SECTION',
  SURVEY_DESIGN = 'SURVEY & DESIGN',
  APPROVALS = 'APPROVALS',
  PROJECT_DELIVERABLES = 'PROJECT DELIVERABLES',
}

export enum NEW_BRIDGE_FIELDS {
  BRIDGE_NO = 'bridgeNo',
  BRIDGE_NAME = 'bridge-name',
  BTN_SAVE = 'btn-save',
  BTN_UPDATE = 'btn-update',
  BTN_NEW = 'btn-new',
  BTN_DELETE = 'btn-delete',
  DISTRICT = 'district',
  DS_DIVISION = 'ds-division',
  GN_DIVISION = 'gn-division',
  SPAN_TYPE = 'span-type',
  BRIDGE_REMARK = 'bridge-remark',
  BRIDGE_SUBCONTRACTOR = 'bridge-subcontractor',
  NORTH_DEGREE = 'north-degree',
  NORTH_MINUTE = 'north-minute',
  NORTH_SECOND = 'north-second',
  EAST_DEGREE = 'east-degree',
  EAST_MINUTE = 'east-minute',
  EAST_SECOND = 'east-second',
  LONGITUDE = 'longitude',
  LATITUDE = 'latitude',
  BRS = 'brs',
  ESSS = 'esss',
  GSS = 'gss',
  SOIL = 'soil',
  TOPO = 'topo',
  DESIGN = 'design',
  BTN_UPDATE_SURVEY = 'btn-update-survey',
  BRIDGE_APPROVAL_MINISTRY = 'bridge-approval-ministry',
  BRIDGE_APPROVAL_DRIVE = 'bridge-approval-drive',
  MOBILIZE_DATE = 'eia-sia-date',
  COMPLETION_DATE = 'completion-date',
  FORMAT_4_STATUS = 'format4-status',
  FORMAT_4_ATTACHMENT = 'format4InputFile',
  HANDED_OVER_DATE = 'handed-over-date',
  TOC_ATTACHMENT = 'tocInputFile',
  BTN_UPDATE_DRIVE = 'btn-update-drive',
  BTN_UPDATE_MINISTRY = 'btn-update-ministry',
  BTN_UPDATE_MOBILIZED_DATE = 'btn-update-mobilized-date',
  BTN_UPDATE_COMPLETION_DATE = 'btn-update-completion-date',
  BTN_UPDATE_HANDED_OVER_DATE = 'btn-update-handed-over-date',
  BTN_UPLOAD_FORMAT_4_ATTACHMENT = 'btn-upload-format-4-attachment',
  BTN_UPLOAD_TOC_INPUT_FILE = 'btn-upload-toc-input-file',
  BTN_CHANGE_FORMAT_4_STATUS = 'btn-change-format-4-status',
}

export enum RENEW_BRIDGE_SECTIONS {
  GENERAL_SECTION = 'GENERAL SECTION',
  LOCATION_NORTH = 'LOCATION-NORTH',
  LOCATION_EAST = 'LOCATION-EAST',
  ATTACHMENT = 'ATTACHMENT',
}

export enum RENEW_BRIDGE_FIELDS {
  CURRENT_BRIDGE_NO = 'current-bridge-no',
  NEW_BRIDGE_NO = 'new-bridge-no',
  BRIDGE_NAME = 'bridge-name',
  DISTRICT = 'district',
  DS_DIVISION = 'ds-division',
  GN_DIVISION = 'gn-division',
  BRIDGE_SPAN = 'bridge-span',
  BRIDGE_REMARK = 'bridge-remark',
  NORTH_DEGREE = 'north-degree',
  NORTH_MINUTE = 'north-minute',
  NORTH_SECOND = 'north-second"',
  EAST_DEGREE = 'east-degree',
  EAST_MINUTE = 'east-minute',
  EAST_SECOND = 'east-second',
  BTN_SAVE = 'btn-save',
  BTN_UPDATE = 'btn-update',
  BTN_NEW = 'btn-new',
  BTN_DELETE = 'btn-delete',
  BTN_UPLOAD = 'btn-upload',
  BTN_DOWNLOAD = 'btn_download'
}

export enum RENEW_BRIDGE_LIST_SECTIONS {
  RENEW_BRIDGE_SEARCH = 'RENEW_BRIDGE_SEARCH',
  RENEW_BRIDGE_LIST_TABLE_DATA = 'RENEW_BRIDGE_LIST_TABLE_DATA',
}

export enum RENEW_BRIDGE_LIST_FIELDS {
  NEW_BRIDGE_NO = 'new-bridge-no',
  CURRENT_BRIDGE_NO = 'current-bridge-no',
  BRIDGE_STATUS = 'bridgeStatus',
  BRIDGE_NAME = 'bridge-name',
  PROVINCE = 'province',
  BTN_DOWNLOAD = 'btn-download',
}

export enum RENEW_BRIDGE_LIST_TABLE_DATA_FIELDS {
  EXIST_BRIDGE = 'Exist-Bridge',
  RENEW_BRIDGE = 'Renew-Bridge',
  NAME = 'Name',
  DISTRICT = 'District',
  PROVINCE = 'Province',
  DS_DIV = 'DS-Div',
  GN_DIV = 'GN-Div',
  SPAN = 'Span',
  RENEW_STATUS = 'Renew-status',
  REPORTED_BY = 'reported-by',
  RENEW_PROOF_ATTACHMENT = 'renew-proof-attachment',
  ACTION = 'Action',
  BTN_CONFIRM = 'btn-confirm',
}

export enum BRIDGE_LIST_SECTIONS {
  BRIDGE_SEARCH = 'BRIDGE_SEARCH',
  BRIDGE_LIST_TABLE_DATA = 'BRIDGE_LIST_TABLE_DATA',
  BRIDGE_LIST_EXCEL_EXPORT = 'BRIDGE_LIST_EXCEL_EXPORT',
}

export enum BRIDGE_LIST_FIELDS {
  BRIDGE_NO = 'bridgeNo',
  BRIDGE_NAME = 'bridge-name',
  PROVINCE = 'province',
  BRIDGE_STATUS = 'bridge-status',
  BTN_DOWNLOAD = 'btn-download',
}

export enum BRIDGE_LIST_TABLE_DATA_FIELDS {
  BRIDGE_NO = 'Bridge-no',
  NAME = 'Name',
  DISTRICT = 'District',
  PROVINCE = 'Province',
  DS_DIV = 'DS-Div',
  GN_DIV = 'GN-Div',
  SPAN_TYPE = 'SpanType',
  SPAN = 'Span',
  SUBCONTRACTOR = 'Subcontractor',
  STATUS = 'Status',
}

export enum BRIDGE_PROGRESS_SECTIONS {
  DATE = 'DATE',
  PROGRESS = 'PROGRESS',
  BRIDGE = 'BRIDGE',
  BRIDGE_PROGRESS_TABLE_DATA = 'BRIDGE_PROGRESS_TABLE_DATA',
}

export enum BRIDGE_PROGRESS_FIELDS {
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  STAGE = 'stage',
  PRECENTAGE = 'precentage', // TODO: Typo error
  BRIDGE_NO = 'bridgeNo',
  BRIDGE_NAME = 'bridge-name',
  UNIT = 'unit',
  BTN_DELETE = 'btn-delete',
  BTN_REFRESH = 'btn-refresh',
}

export enum BRIDGE_PROGRESS_TABLE_DATA_FIELDS {
  BTN_CONFIRM = 'btn-confirm',
  CREATED_DATE = 'created-date',
  UNIT = 'unit',
  BRIDGE_NO = 'bridge-no',
  SPAN = 'span',
  SIDE = 'side',
  PROGRESS_STAGE = 'progress-stage',
  PERCENTAGE = 'percentage',
  MORE = 'more',
  GENERATED_BY = 'generated-by',
}

export enum BRIDGE_PROGRESS_NOT_REPORTED_SECTIONS {
  BRIDGE_PROGRESS_NOT_REPORTED_SEARCH = 'BRIDGE_PROGRESS_NOT_REPORTED_SEARCH',
  BRIDGE_PROGRESS_NOT_REPORTED_TABLE_DATA = 'BRIDGE_PROGRESS_NOT_REPORTED_TABLE_DATA',
}

export enum BRIDGE_PROGRESS_NOT_REPORTED_FIELDS {
  DELAYED_DURATION = 'delayed_duration',
  BRIDGE_NO = 'bridgeNo',
  UNIT = 'unit',
  ISSUE_STATUS = 'issueStatus',
  BTN_PDF = 'btn-pdf',
  BTN_EXCEL = 'btn-excel',
}

export enum BRIDGE_PROGRESS_NOT_REPORTED_TABLE_DATA_FIELDS {
  ACTION = 'action',
  UNIT = 'unit',
  BRIDGE_NO = 'bridge-no',
  SIDE = 'side',
  PROGRESS_STAGE = 'progress-stage',
  PERCENTAGE = 'percentage',
  DELAYED_DURATION = 'delayed_duration',
  UPDATED_TIME = 'updated-time',
  ISSUES = 'issues',
}

export enum BRIDGE_ISSUE_SECTIONS {
  DATE = 'DATE',
  ISSUE = 'ISSUE',
  BRIDGE = 'BRIDGE',
  BRIDGE_ISSUE_TABLE_DATA = 'BRIDGE_ISSUE_TABLE_DATA',
}

export enum BRIDGE_ISSUE_FIELDS {
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  TYPE = 'type',
  ESTIMATED_DATE = 'estimatedDate',
  BRIDGE_NO = 'bridgeNo',
  UNIT = 'unit',
  ISSUE_STATUS = 'issue-status',
  BTN_REFRESH = 'btn-refresh',
  BRIDGE_NAME = 'bridge-name',
}

export enum BRIDGE_ISSUE_TABLE_DATA_FIELDS {
  CREATED_DATE = 'created-date',
  UNIT = 'unit',
  BRIDGE_NO = 'bridge-no',
  SPAN_TYPE = 'span-type',
  ESTIMATED_DATE = 'estimated-date',
  REMARKS = 'remarks',
  MORE = 'more',
  GENERATED_BY = 'generated-by',
  ACTION = 'action',
}

export enum BRIDGE_SPAN_SECTIONS {
  PROJECT_CONFIG = 'PROJECT CONFIG',
  SAVE_SPAN = 'SAVE SPAN',
}

export enum BRIDGE_SPAN_FIELDS {
  BRIDGE_COUNT = 'bridge-count',
  SPAN_TYPE = 'spanType',
  SPAN = 'span',
  COUNT = 'count',
  BTN_SAVE_SPAN = 'btn-save-span',
  BTN_LIST_SPAN = 'btn-list-span',
}

export enum SUB_CONTRACTOR_SECTIONS {
  GENERAL_SECTION = 'GENERAL SECTION',
}

export enum SUB_CONTRACTOR_FIELDS {
  SC_NAME = 'sc-name',
  SC_REMARKS = 'sc-remarks',
  SC_MOBILE = 'sc-mobile',
  TELEPHONE = 'telephone',
  SC_REF_NO = 'sc-ref-no',
  SC_STATUS = 'sc-status',
  BTN_SAVE = 'btn-save',
  BTN_UPDATE = 'btn-update',
  BTN_NEW = 'btn-new',
  BTN_DELETE = 'btn-delete',
}

export enum SUB_CONTRACTOR_LIST_SECTIONS {
  SUB_CONTRACTOR_SEARCH = 'SUB_CONTRACTOR_SEARCH',
  SUB_CONTRACTOR_LIST_TABLE_DATA = 'SUB_CONTRACTOR_LIST_TABLE_DATA',
}

export enum SUB_CONTRACTOR_LIST_FIELDS {
  SUBCONTRACTOR_NAME = 'subcontractor-name',
  SUBCONTRACTOR_MOBILE = 'subcontractor-mobile',
  SUBCONTRACTOR_STATUS = 'subcontractor-status',
  BTN_DOWNLOAD = 'btn-download',
}

export enum SUB_CONTRACTOR_LIST_TABLE_DATA_FIELDS {
  NAME = 'Name',
  MOBILE_NO = 'Mobile-No',
  TELEPHONE = 'Telephone',
  REMARKS = 'Remarks',
  REFERENCE = 'Reference',
  STATUS = 'Status',
}

export enum USER_REGISTRATION_SECTIONS {
  GENERAL_SECTION = 'GENERAL SECTION',
}

export enum USER_REGISTRATION_FIELDS {
  USER_ID = 'userID',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  MOBILE = 'mobile',
  USER_NAME = 'userName',
  USER_DESIGNATION = 'user-designation',
  USER_ROLE = 'userRole',
  UNIT = 'unit',
  PASSWORD = 'password',
  PHASES = 'phases',
  BTN_SAVE = 'btn-save',
  BTN_UPDATE = 'btn-update',
  BTN_NEW = 'btn-new',
  BTN_DELETE = 'btn-delete',
  BTN_ACTIVE = 'btn-active',
}

export enum USER_LIST_SECTIONS {
  USER_SEARCH = 'USER_SEARCH',
  USER_TABLE_DATA = 'USER_TABLE_DATA',
}

export enum USER_LIST_FIELDS {
  USER_NAME = 'username',
  NAME = 'name',
  UNIT = 'unit',
  BTN_DOWNLOAD = 'btn-download',
  BTN_SYNC = 'btn-sync',
}

export enum USER_TABLE_DATA_FIELDS {
  NAME = 'Name',
  USER_NAME = 'User-Name',
  EMAIL = 'Email',
  TELEPHONE = 'Telephone',
  UNIT = 'Unit',
  DESIGNATION = 'Designation',
  USER_ROLE = 'User-Role',
}

export enum BRIDGE_REPORT_SECTIONS {
  UNIT_SUMMARY_REPORT = 'UNIT_SUMMARY_REPORT',
  BRIDGE_WARRANTY_REPORT = 'BRIDGE_WARRANTY_REPORT',
  PROJECT_DELIVERABLE_REPORT = 'PROJECT_DELIVERABLE_REPORT',
  CURRENT_PROGRESS_REPORT = 'CURRENT_PROGRESS_REPORT',
  BRIDGE_ISSUE_REPORT = 'BRIDGE_ISSUE_REPORT',
  BRIDGE_SPAN_BREAKDOWN_REPORT = 'BRIDGE_SPAN_BREAKDOWN_REPORT',
}

export enum EXCEL_REPORTS_SECTIONS {
  CURRENT_PROGRESS_EXCEL_REPORT = 'CURRENT_PROGRESS_EXCEL_REPORT',
}

export enum BRIDGE_REPORT_FIELDS {
  UNIT_SUMMARY = 'unit-summary',
  BTN_VIEW_UNIT_SUMMARY = 'btn-view-unit-summary',
  BRIDGE_WARRANTY = 'bridge-warranty',
  BTN_VIEW_BRIDGE_WARRANTY = 'btn-view-bridge-warranty',
  PROJECT_DELIVERABLE = 'project-deliverable',
  BTN_VIEW_PROJECT_DELIVERABLE = 'btn-view-project-deliverable',
  CURRENT_PROGRESS = 'current-progress',
  BTN_GENERATE_CURRENT_PROGRESS = 'btn-generate-current-progress',
  BTN_VIEW_CURRENT_PROGRESS = 'btn-view-current-progress',
  BRIDGE_ISSUE = 'bridge-issue',
  BTN_GENERATE_BRIDGE_ISSUE = 'btn-generate-bridge-issue',
  BTN_VIEW_BRIDGE_ISSUE = 'btn-view-bridge-issue',
  BRIDGE_SPAN_BREAKDOWN = 'bridge-span-breakdown',
  BTN_VIEW_BRIDGE_SPAN_BREAKDOWN = 'btn-view-bridge-span-breakdown',
}

export enum MONTHLY_REPORT_SECTIONS {
  MONTHLY_REPORT_SEARCH = 'MONTHLY_REPORT_SEARCH',
  MONTHLY_REPORT_TABLE_DATA = 'MONTHLY_REPORT_TABLE_DATA',
  MONTHLY_REPORT_GENERATE = 'MONTHLY_REPORT_GENERATE',
}

export enum MONTHLY_REPORT_FIELDS {
  YEAR = 'year',
  MONTH = 'month',
  BTN_REFRESH = 'btn-refresh',
  BTN_APPROVE_REPORT = 'btn-approve-report',
  BTN_DELETE_REPORT_FOR_GENERATED = 'btn-delete-report-generated',
  BTN_DELETE_REPORT_FOR_FAILED_AND_SUBMITTED = 'btn-delete-report-failed-submitted',
  BTN_DOWNLOAD_REPORT = 'btn-download-report',
  GENERATE_REPORT = 'generate-report',
}

export enum DASHBOARD_SECTIONS {
  CHARTS = 'DASHBOARD_CHARTS',
}

export enum DASHBOARD_FIELDS {
  DASHBOARD_ISSUE_NOT_REPORTED = 'dashboard-issue-not-reported',
  DASHBOARD_WAITING_TOC = 'dashboard-waiting-toc',
  DASHBOARD_FORMAT4_SUBMITTED = 'dashboard-format4-submitted',
  DASHBOARD_READY_TO_SUPERSTRUCTURE = 'dashboard-ready-to-superstructure',
  DASHBOARD_COMPLETED_F4 = 'dashboard-completed-f4',
  DASHBOARD_BRIDGE_PROGRESS_SUMMARY = 'dashboard-bridge-progress-summary',
  DASHBOARD_BRIDGE_ISSUE_SUMMARY = 'dashboard-bridge-issue-summary',
  DASHBOARD_ONGOING_ISSUE_PROGRESS = 'dashboard-ongoing-issue-progress',

}

export enum UI_FIELD_TYPE {
  RANGE_SELECTION = 'RANGE_SELECTION',
  DEFAULT_SELECTION = 'DEFAULT_SELECTION',
}

export enum BUTTON_DISPLAY_NAMES {
  EXCEL = 'Excel',
  VIEW = 'View'
}
