import {Injectable} from '@angular/core';
import {JWTParserService} from './security/jwt.parser.service';
import {Token} from '../../model/security/token.model';
import {AuthService} from './auth.service';
import {UserModel} from '../../model/security/user.model';
import {AppConfig} from '../app.config';

@Injectable()
export class TokenService {

  public static ITEM_TOKEN = 'token';
  public static ITEM_TOKEN_OBJ = 'token-obj';
  public static TENANT = 'tenant';

  public static getTokenString(): string {
    return localStorage.getItem(TokenService.ITEM_TOKEN);
  }


  constructor(private jwtParserService: JWTParserService,
              private authService: AuthService) {
  }

  public async setLoginInfo(): Promise<Token> {
    let tokenString = null;
    await this.authService.getToken().then(value => {
      tokenString = value;
    })
    const jwtTokenObject = this.jwtParserService.getJwtTokenObject(tokenString);
    const token: Token = JSON.parse(JSON.stringify(jwtTokenObject));
    sessionStorage.setItem(TokenService.ITEM_TOKEN_OBJ, JSON.stringify(token));
    return token;
  }


  public hasToken(): boolean {
    const token: string = localStorage.getItem(TokenService.ITEM_TOKEN);
    if (token == null || token === '') {
      return false;
    } else {
      return true;
    }
  }

  public async getTokenObject(): Promise<Token> {
    const tokenString = await this.authService.getToken();
    return this.jwtParserService.getJwtTokenObject(tokenString);
  }

  public async getUpdatedToken(): Promise<string> {
    if (this.authService.getAuthService().isTokenExpired()) {
      return this.authService.getAuthService().getKeycloakInstance().updateToken(60).then(() =>
        this.authService.getAuthService().getKeycloakInstance().token || '');
    } else {
      return this.authService.getAuthService().getKeycloakInstance().token || '';
    }
  }

  public logoutAndClearToken() {
    this.authService.logout();
    this.clearToken();
  }

  public clearToken() {
    localStorage.clear();
    sessionStorage.clear();
  }

  public async getExistingUserExchange(): Promise<UserModel> {
    const userExchange: UserModel = new UserModel();
    const token = await this.getTokenObject();
    userExchange.id = token.sub;
    userExchange.firstName = token.given_name;
    userExchange.lastName = token.family_name;
    userExchange.email = token.email;
    userExchange.groups = token.groups.find((group: any) => new RegExp(AppConfig.SECURITY_GROUP_REGEX).test(group));
    userExchange.username = token.preferred_username;
    userExchange.fullName = token.name;
    return userExchange;
  }
}
