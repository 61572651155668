import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {TokenService} from '../token.service';
import {AppConfig, SESSION_STORAGE_KEYS} from '../../app.config';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tenantId = 'default';
    let countryCode = 'default';
    let formattedUrl = req.url;
    if (req.url.startsWith(AppConfig.BACKEND_API_BASE_URL)) {
      tenantId = sessionStorage.getItem(TokenService.TENANT);
      countryCode = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.SELECTED_COUNTRY_CODE));
      formattedUrl = formattedUrl.replace(AppConfig.PATTERN_COUNTRY_CODE, countryCode)
    }
    const request = req.clone({
      headers: req.headers.set('X-Country', countryCode).set('X-Source', 'Janson-web-app').set('X-Source-Version', environment.APP_VERSION).set('X-TenantID', tenantId)
      , url: formattedUrl
    });
    return next.handle(request);
  }
}
