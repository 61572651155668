import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {AppCommonMethods} from '../../../app.common.methods';
import {
  BRIDGE_ISSUE,
  BRIDGE_LIST,
  BRIDGE_MODULE,
  BRIDGE_PROGRESS,
  BRIDGE_PROGRESS_NOT_REPORTED,
  BRIDGE_SPAN,
  NEW_BRIDGE,
  RENEW_BRIDGE,
  RENEW_BRIDGE_LIST
} from '../../../ui.component.config';

@Injectable()
export class BridgeAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService, private appCommonMethod: AppCommonMethods) {
  }

  canActivate(): boolean {
    let url: string = this._router.getCurrentNavigation().finalUrl.toString();
    const urlParts = url.split('/');
    if (urlParts.length === 4) {
      urlParts.pop();
    }
    url = urlParts.join('/');
    let status = false;

    switch (url) {
      case FinalUrls.BRIDGE_NEW:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, NEW_BRIDGE);
        break;

      case FinalUrls.BRIDGE_RENEW:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, RENEW_BRIDGE);
        break;

      case FinalUrls.BRIDGE_RENEW_LIST:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, RENEW_BRIDGE_LIST);
        break;

      case FinalUrls.BRIDGE_PROGRESS:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_PROGRESS);
        break;

      case FinalUrls.BRIDGE_PROGRESS_NOT_REPORTED:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_PROGRESS_NOT_REPORTED);
        break;

      case FinalUrls.BRIDGE_ISSUE:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_ISSUE);
        break;

      case FinalUrls.BRIDGE_LIST:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_LIST);
        break;

      case FinalUrls.SPAN_MASTER:
        status = this.appCommonMethod.getUIComponentActive(BRIDGE_MODULE, BRIDGE_SPAN);
        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }

}
