import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BridgeIssueTypeAPI} from '../backend.api.config';
import {BridgeIssueTypeModel} from '../../model/bridge.issue.type.model';

@Injectable({
  providedIn: 'root'
})
export class BridgeIssueTypeService {
  constructor(private _http: HttpClient) {
  }

  public listBridgeIssueTypes(): Observable<BridgeIssueTypeModel[]> {
    return this._http.get<BridgeIssueTypeModel[]>(BridgeIssueTypeAPI.LIST);
  }

  public saveBridgeIssueType(bridgeIssueType: BridgeIssueTypeModel) {
    return this._http.post(BridgeIssueTypeAPI.SAVE, bridgeIssueType);
  }

  public deleteBridgeIssueType(bridgeIssueTypeId: number) {
    return this._http.delete(BridgeIssueTypeAPI.DELETE + '/' + bridgeIssueTypeId);
  }

  enableIssueType(issueTypeId: number) {
    return this._http.put(BridgeIssueTypeAPI.ENABLE + '/' + issueTypeId, {});
  }

  disableIssueType(issueTypeId: number) {
    return this._http.put(BridgeIssueTypeAPI.DISABLE + '/' + issueTypeId, {});
  }

  public listAllEnabledBridgeIssueTypes(): Observable<BridgeIssueTypeModel[]> {
    return this._http.get<BridgeIssueTypeModel[]>(BridgeIssueTypeAPI.LIST_ENABLED);
  }
}
