import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TenantAPI, UIGenerateAPI} from '../backend.api.config';
import {Observable} from 'rxjs';
import {ResponseModel} from '../../model/response.model';
import {CountryModel} from '../../model/country.model';

@Injectable()
export class UiService {
  constructor(private http: HttpClient) {
  }

  public getAllConfiguredUIModules(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(UIGenerateAPI.GENERATE);
  }

  public getAllCountries(tenantList): Observable<CountryModel[]> {
    const params = new HttpParams().set('commaSeparatedTenantList', tenantList);
    return this.http.get<CountryModel[]>(TenantAPI.COUNTRY, {params: params});
  }
}
