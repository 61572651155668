import {HttpClient} from '@angular/common/http';
import {AdminMonitorAPI} from '../../backend.api.config';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IAdminMonitorService} from '../../../admin-monitor/expose-out/service/admin.monitor.service';

@Injectable()
export class AdminMonitorService implements IAdminMonitorService {

  constructor(private _http: HttpClient) {
  }

  public getHealth(): Observable<any> {
    return this._http.get(AdminMonitorAPI.HEALTH);
  }

  public getInfo(): Observable<any> {
    return this._http.get(AdminMonitorAPI.INFO);
  }

  public getEnv(): Observable<any> {
    return this._http.get(AdminMonitorAPI.ENV);
  }

  public getConfigProps(): Observable<any> {
    return this._http.get(AdminMonitorAPI.CONFIG_PROPS);
  }

  public getMetrics(): Observable<any> {
    return this._http.get(AdminMonitorAPI.METRICS);
  }

  public getAuditEvents(): Observable<any> {
    return this._http.get(AdminMonitorAPI.AUDIT_EVENTS);
  }

  public getMonitor(): Observable<any> {
    return this._http.get(AdminMonitorAPI.MONITOR);
  }

  public getAccessMonitorData(page: number): Observable<any> {
    return this._http.get(AdminMonitorAPI.ACCESS_MONITOR + '/' + page);
  }

  public getRequestMonitorData(page: number): Observable<any> {
    return this._http.get(AdminMonitorAPI.REQUEST_MONITOR + '/' + page);
  }

  public getAccessStatusStreamURL(): string {
    return AdminMonitorAPI.ACCESS_STATUS_STREAM_URL;
  }

  public viewMetric(path: string): Observable<any> {
    return this._http.get(AdminMonitorAPI.METRICS + '/' + path);
  }

  public viewMonitorEndPoint(path: string): Observable<any> {
    return this._http.get(path);
  }
}
