import {Observable} from 'rxjs';

export abstract class IAdminMonitorService {

  abstract getMonitor(): Observable<any>;

  abstract getHealth(): Observable<any>;

  abstract getInfo(): Observable<any>;

  abstract getEnv(): Observable<any>;

  abstract getConfigProps(): Observable<any>;

  abstract getMetrics(): Observable<any>;

  abstract getAuditEvents(): Observable<any>;

  abstract getAccessMonitorData(page: number): Observable<any>;

  abstract getRequestMonitorData(page: number): Observable<any>;

  abstract getAccessStatusStreamURL(): string;

  abstract viewMetric(path: string);

  abstract viewMonitorEndPoint(path: string);
}
