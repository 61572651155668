import {AppConfig} from './app.config';

const API_ROOT = AppConfig.BACKEND_API_BASE_URL + '/api';
const API_AUTH = AppConfig.BACKEND_API_AUTH_URL + '/api';
const API_TENANT_CONFIG = AppConfig.BACKEND_API_TENANT_CONFIG + '/api';
const API_MONITOR_ROOT = AppConfig.BACKEND_API_BASE_URL + '/actuator';

export const AuthenticationAPI = {
  SIGNIN: AppConfig.BACKEND_API_BASE_URL + '/oauth/token',
  SIGNUP: API_ROOT + '/user/registration'
};

export const APIHeaders = {
  APPLICATION_JSON: 'application/json'
};

export const BridgeAPI = {
  SAVE: API_ROOT + '/bridge/save',
  UPDATE: API_ROOT + '/bridge/update',
  UPDATE_F4_STATUS: API_ROOT + '/bridge/update/f4-status',
  UPDATE_DOCS_DATE: API_ROOT + '/bridge/update/documents-date',
  UPDATE_ATTACHMENT: API_ROOT + '/bridge/update/attachment',
  UPDATE_SURVEY_DESIGN: API_ROOT + '/bridge/update/survey-design',
  UPDATE_APPROVAL_DRIVE: API_ROOT + '/bridge/update/approval-drive',
  UPDATE_APPROVAL_MINISTRY: API_ROOT + '/bridge/update/approval-ministry',
  LIST: API_ROOT + '/bridge/list',
  LIST_NO: API_ROOT + '/bridge/list-no',
  LIST_BRIDGE_STATUS: API_ROOT + '/bridge/list/bridge-status',
  LIST_NO_STATUS_WISE: API_ROOT + '/bridge/list-no-status-wise',
  GET: API_ROOT + '/bridge',
  GET_DOCUMENT: API_ROOT + '/bridge/get-document',
  GET_ALLOCATED_BRIDGE_COUNT: API_ROOT + '/bridge/allocated-bridge-count'
};
export const BridgeProgressAPI = {
  GET: API_ROOT + '/bridge-progress',
  GET_LATEST_BY_BRIDGE: API_ROOT + '/bridge-progress/latest',
  DELETE_LATEST_BY_BRIDGE: API_ROOT + '/bridge-progress/delete',
  LIST: API_ROOT + '/bridge-progress/list/view',
  LIST_CURRENT_PROGRESS: API_ROOT + '/bridge-progress/list/current-progress',
  LIST_ALL_PROGRESS: API_ROOT + '/bridge-progress/list/all',
  LIST_NOT_REPORTED: API_ROOT + '/bridge-progress/list/not-reported-view',
};
export const BridgeIssueAPI = {
  GET: API_ROOT + '/bridge-issue',
  LIST: API_ROOT + '/bridge-issue/list/view',
  LIST_ALL_BY_BRIDGE: API_ROOT + '/bridge-issue/list/all',
  LIST_ISSUES: API_ROOT + '/bridge-issue/list/all-issues',
  COMPLETE_ISSUE: API_ROOT + '/bridge-issue/complete-issue',
};
export const BridgeIssueTypeAPI = {
  LIST: API_ROOT + '/bridge-issue-type/list',
  SAVE: API_ROOT + '/bridge-issue-type/save',
  DELETE: API_ROOT + '/bridge-issue-type/delete',
  ENABLE: API_ROOT + '/bridge-issue-type/enable',
  DISABLE: API_ROOT + '/bridge-issue-type/disable',
  LIST_ENABLED: API_ROOT + '/bridge-issue-type/list/enable',
};
export const DesignationAPI = {
  SAVE: API_ROOT + '/designation/save',
  LIST: API_ROOT + '/designation/list',
  DELETE: API_ROOT + '/designation/delete',
};
export const BridgeStageAPI = {
  LIST: API_ROOT + '/bridge-stage/list',
  SAVE: API_ROOT + '/bridge-stage/save',
  DELETE: API_ROOT + '/bridge-stage/delete',
  ENABLE: API_ROOT + '/bridge-stage/enable',
  DISABLE: API_ROOT + '/bridge-stage/disable',
};
export const RenewBridgeAPI = {
  SAVE: API_ROOT + '/renew-bridge/save',
  UPDATE: API_ROOT + '/renew-bridge/update',
  TRANSFER: API_ROOT + '/renew-bridge/transfer',
  LIST: API_ROOT + '/renew-bridge/list',
  LIST_NO: API_ROOT + '/renew-bridge/list-no',
  LIST_RENEW_BRIDGE_STATUS: API_ROOT + '/renew-bridge/list/bridge-status',
  GET: API_ROOT + '/renew-bridge/',
  UPDATE_ATTACHMENT: API_ROOT + '/renew-bridge/update/attachment',
  GET_DOCUMENT: API_ROOT + '/renew-bridge/get-document'
};
export const UserAPI = {
  SAVE: AuthenticationAPI.SIGNUP,
  UPDATE: API_AUTH + '/user/update',
  UPDATE_PERSONAL_INFO: API_AUTH + '/user/update/info',
  UPDATE_PASSWORD: API_AUTH + '/user/update/password',
  DELETE: API_AUTH + '/user/delete/',
  LIST: API_AUTH + '/user/list',
  SYNCED_LIST: API_AUTH + '/user/synced-list',
  GET: API_AUTH + '/user/',
  IMAGE: API_AUTH + '/user/image',
  ENABLE: API_AUTH + '/user/enable',
  DISABLE: API_AUTH + '/user/disable',
  LOCK: API_AUTH + '/user/lock',
  UNLOCK: API_AUTH + '/user/unlock',
  ADD_GROUP: API_AUTH + '/user/add-group',
  SYNC_USER_LIST: API_AUTH + '/user/sync',
};
export const GroupAPI = {
  LIST: API_ROOT + '/group/list',
  LIST_ROLES: API_ROOT + '/group/role/list',
};
export const SubContractorAPI = {
  SAVE: API_ROOT + '/sub-contractor/save',
  UPDATE: API_ROOT + '/sub-contractor/update',
  LIST: API_ROOT + '/sub-contractor/list',
  LIST_NAME: API_ROOT + '/sub-contractor/list-name',
  GET: API_ROOT + '/sub-contractor',
};
export const UnitAPI = {
  LIST: API_ROOT + '/unit/list',
  SAVE: API_ROOT + '/unit/save',
  DELETE: API_ROOT + '/unit/delete'

};
export const DistrictAPI = {
  LIST: API_ROOT + '/district/list',
  SAVE: API_ROOT + '/district/save',
  DELETE: API_ROOT + '/district/delete'
};
export const DashboardAPI = {
  BRIDGE_ISSUE_STATUS_SUMMARY: API_ROOT + '/dashboard/bridge-issue-status-summary',
  BRIDGE_ISSUE_DURATION_SUMMARY: API_ROOT + '/dashboard/bridge-issue-ongoing-summary',
  BRIDGE_STATUS_SUMMARY: API_ROOT + '/dashboard/bridge-status-summary',
  BRIDGE_PROGRESS_OVERVIEW: API_ROOT + '/dashboard/bridge-progress-overview'
};
export const UIGenerateAPI = {
  GENERATE: AppConfig.BACKEND_API_BASE_URL + '/ui/config/user',
}
export const AdminMonitorAPI = {
  HEALTH: API_MONITOR_ROOT + '/health',
  INFO: API_MONITOR_ROOT + '/info',
  ENV: API_MONITOR_ROOT + '/env',
  CONFIG_PROPS: API_MONITOR_ROOT + '/configprops',
  METRICS: API_MONITOR_ROOT + '/metrics',
  AUDIT_EVENTS: API_MONITOR_ROOT + '/auditevents',
  ACCESS_MONITOR: API_MONITOR_ROOT + '/custom/latest-access-monitor',
  REQUEST_MONITOR: API_MONITOR_ROOT + '/custom/latest-request-monitor',
  MONITOR: API_MONITOR_ROOT,
  ACCESS_STATUS_STREAM_URL: API_MONITOR_ROOT + '/stream/access-status'
};

export const BridgeSpanAPI = {
  SAVE: API_ROOT + '/bridge-span/save',
  LIST: API_ROOT + '/bridge-span/list',
  DELETE: API_ROOT + '/bridge-span',
  SPAN_LIST_TYPE: API_ROOT + '/bridge-span/list/span-types',
};

export const ReportAPI = {
  REPORT_BRIDGE_PROGRESS: API_ROOT + '/report/bridge-progress-summary',
  REPORT_BRIDGE_PROGRESS_NOT_REPORTED: API_ROOT + '/report/bridge-progress-not-reported',
  REPORT_BRIDGE_WARRANTY: API_ROOT + '/report/bridge-warranty',
  REPORT_PROJECT_DELIVERABLE: API_ROOT + '/report/project-deliverable',
  REPORT_BRIDE_SPAN_BREAKDOWN: API_ROOT + '/report/bridge-span',
  REPORT_CURRENT_PROGRESS: API_ROOT + '/report/bridge-current-progress',
  REPORT_UNIT_PROGRESS: API_ROOT + '/report/unit-progress',
  REPORT_BRIDGE_ISSUES: API_ROOT + '/report/bridge-issues',
  REPORT_MONTHLY_REPORT_SELECTION: API_ROOT + '/report/monthly-report-selection',
  REPORT_MONTHLY_REPORT_BRIDGE_LIST: API_ROOT + '/report/monthly-report-bridge-list',
  REPORT_MONTHLY_REPORT_GENERATE: API_ROOT + '/report/monthly-report-submit',
  REPORT_MONTHLY_REPORT_LIST: API_ROOT + '/report/monthly-report-list',
  REPORT_MONTHLY_REPORT_APPROVE: API_ROOT + '/report/monthly-report-approve',
  REPORT_MONTHLY_REPORT_DELETE: API_ROOT + '/report/monthly-report-delete',
  REPORT_MONTHLY_REPORT_DOWNLOAD: API_ROOT + '/report/monthly-report-download',
  REPORT_MONTHLY_REPORT_COMMENT: API_ROOT + '/report/monthly-report-comment'
};
export const AdminAPI = {
  ROLE: API_ROOT + '/admin/populate/role',
  GROUP: API_ROOT + '/admin/populate/group',
};
export const ApplicationConfigAPI = {
  SAVE: API_ROOT + '/application/config-data/save',
};

// TENANT API Config
export const TenantAPI = {
  COUNTRY: API_TENANT_CONFIG + '/tenant/country',
}

