import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {AppCommonMethods} from '../../../app.common.methods';
import {USER_LIST, USER_MODULE, USER_REGISTRATION} from '../../../ui.component.config';

@Injectable()
export class UserAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService, private appCommonMethod: AppCommonMethods) {
  }

  canActivate(): boolean {
    let url: string = this._router.getCurrentNavigation().finalUrl.toString();
    let status = false;
    const urlParts = url.split('/');
    if (urlParts.length === 4) {
      urlParts.pop();
    }
    url = urlParts.join('/');

    switch (url) {
      case FinalUrls.USER_REGISTRATION:
        status = this.appCommonMethod.getUIComponentActive(USER_MODULE, USER_REGISTRATION);

        break;

      case FinalUrls.USER_LIST:
        status = this.appCommonMethod.getUIComponentActive(USER_MODULE, USER_LIST);

        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }

}
