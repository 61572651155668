import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenService} from '../../token.service';
import {FinalUrls} from './final.urls';
import {AppCommonMethods} from '../../../app.common.methods';
import {BRIDGE_REPORT, MONTHLY_REPORT, REPORTS_MODULE} from '../../../ui.component.config';

@Injectable()
export class ReportAuthGuard implements CanActivate {

  constructor(private _router: Router, private tokenService: TokenService, private appCommonMethod: AppCommonMethods) {
  }

  canActivate(): boolean {
    const url: string = this._router.getCurrentNavigation().finalUrl.toString();
    let status = false;

    switch (url) {

      case FinalUrls.BRIDGE_REPORT:
        status = this.appCommonMethod.getUIComponentActive(REPORTS_MODULE, BRIDGE_REPORT);
        break;

      case FinalUrls.MONTHLY_REPORT:
        status = this.appCommonMethod.getUIComponentActive(REPORTS_MODULE, MONTHLY_REPORT);
        break;
    }

    if (!status) {
      FinalUrls.navigateToDashboard(this._router, url);
    }

    return status;
  }

}
