<ng-sidebar-container
  [ngClass]="{'mode-boxed': isBoxed, 'sidebar-opened': isOpened, 'theme-light': theme == 'light', 'theme-dark': theme == 'dark', 'mode-push': _mode == 'push', 'mode-dock': _mode == 'dock', 'mode-over': _mode == 'over', 'mode-slide': _mode == 'slide', 'no-footer': options?.removeFooter, 'map-header': options?.mapHeader}"
  class="app">
  <ng-sidebar #sidebar [(mode)]="_mode" [(opened)]="isOpened" [autoCollapseWidth]="'991'"
              [closeOnClickOutside]="isOver()"
              [dockedSize]="'80px'" [position]="'left'" [showBackdrop]="isOver()" [sidebarClass]="'sidebar-panel'">
    <nav class="navbar custom-navbar main-brand">
      <a [routerLink]="['/']" class="navbar-brand mr-auto ml-auto">
        <img alt="" [src]="appInfo.logoUrl" style="height: 50px; width: auto;">
      </a>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a (click)="toogleSidebar()" class="nav-link" href="javascript:">
            <i *ngIf="_mode === 'over' && !isOver()" class="hamburger-icon v2">
              <span></span>
            </i>
          </a>
        </li>
      </ul>
    </nav>
    <!-- main navigation -->
    <nav class="menu">
      <ul appAccordion class="navigation">
        <li *ngFor="let menuitem of menuItems" appAccordionLink class="navigation-item"
            group="{{menuitem.state}}">
          <a *ngIf="menuitem.type === 'link'"
             [routerLink]="['/', menuitem.state]" appAccordionToggle
             class="navigation-link">
            <i class="{{ menuitem.icon }}"></i>
            <span>{{ menuitem.name }}</span>
            <!--<span>{{ menuitem.name | translate }}</span>-->
            <span class="mr-auto"></span>
            <span *ngFor="let badge of menuitem.badge" class="badge badge-{{ badge.type }}">{{ badge.value }}</span>
          </a>
          <a *ngIf="menuitem.type === 'extLink'" appAccordionToggle class="navigation-link"
             href="{{menuitem.state}}">
            <i class="icon icon-{{ menuitem.icon }}"></i>
            <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span *ngFor="let badge of menuitem.badge" class="badge badge-{{ badge.type }}">{{ badge.value }}</span>
          </a>
          <a *ngIf="menuitem.type === 'extTabLink'" appAccordionToggle class="navigation-link"
             href="{{menuitem.state}}"
             target="_blank">
            <i class="icon icon-{{ menuitem.icon }}"></i>
            <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span *ngFor="let badge of menuitem.badge" class="badge badge-{{ badge.type }}">{{ badge.value }}</span>
          </a>
          <a *ngIf="menuitem.type === 'sub'" appAccordionToggle class="navigation-link" href="javascript:">
            <i class="{{ menuitem.icon }}"></i>
            <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span *ngFor="let badge of menuitem.badge" class="badge badge-{{ badge.type }}">{{ badge.value }}</span>
            <i class="menu-caret icon icon-arrows-right"></i>
          </a>
          <ul *ngIf="menuitem.type === 'sub'" class="navigation-submenu">
            <li *ngFor="let childitem of menuitem.children" class="navigation-item" routerLinkActive="open">
              <a [routerLink]="['/', menuitem.state, childitem.state]"
                 class="navigation-link relative">{{
                childitem.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /main navigation -->
    <nav class="navbar custom-navbar secondary-brand">
      <a [routerLink]="['/']" class="navbar-brand mr-auto ml-auto">
        <div class="logo-container">
          <img alt="" src="assets/images/logos/tiesense_logo.png" class="logo-image">
          <div class="text-container">
            <h6 class="logo-text">TIESENSE</h6>
            <p class="version-text">{{appVersion}}</p>
          </div>
        </div>
      </a>
    </nav>
  </ng-sidebar>

  <div class="app-inner">
    <nav class="navbar custom-navbar bg-faded main-header">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a (click)="toogleSidebar()" class="nav-link" href="javascript:">
            <i *ngIf="_mode !== 'dock'" class="hamburger-icon v2">
              <span></span>
            </i>
          </a>
        </li>
      </ul>
      <span class="navbar-heading hidden-xs-down">{{options?.heading}}</span>
      <span class="mr-auto"></span>
      <ul class="navbar-nav">

        <li class="nav-item" ngbDropdown>

          <a class="nav-link blinking" href="javascript:">{{appEnvDisplayName}}</a>
          <a
            href="javascript:" style="font-weight: bold" ngbTooltip="Current Country Date and Time">{{currentTime}}</a>

          <a class="nav-link" href="javascript:">
            <div class="d-inline-block" ngbDropdown>
              <button (click)="switchPhase()" class="btn btn-icon btn-facebook mb-1 mr-1"
                      ngbTooltip="Click to switch Phase"
                      placement="bottom">
                <i class="fa fa fa-chevron-circle-right" style="font-size:19px;"></i>
                {{currentTenant}}
              </button>
            </div>
          </a>

          <a class="nav-link">
            <i class="fa fa-user-circle mr-2 mb-1" style="font-size:23px;"></i>
            <span (click)="openUserProfile()" class="font-weight-bold text-secondary text-uppercase">
           {{existName}}
          </span>
          </a>

        </li>
        <li class="nav-item">
          <span class="nav-divider"></span>
        </li>
        <a class="nav-link">
          <i (click)="signOut()" class="icon-power"></i>
        </a>

        <li class="nav-item">
          <span class="nav-divider"></span>
        </li>
        <li class="nav-item">
          <a appToggleFullscreen class="nav-link" href="javascript:">
            <i class="fi flaticon-fullscreen"></i>
          </a>
        </li>

      </ul>
    </nav>

    <div class="main-content">
      <router-outlet>
        <ngx-spinner [fullScreen]="false"></ngx-spinner>
      </router-outlet>
      <nav class="navbar custom-navbar navbar-light main-footer small">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="javascript:">
              <span>Developed by Sysensor®</span>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav hidden-xs-down">
          <li class="nav-item">
            <a class="nav-link" href="javascript:">Frontend Version {{appVersion}}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

</ng-sidebar-container>

<ng-template #search let-c="close" let-d="dismiss">
  <form action="" class="search__form">
    <input autocapitalize="off" autocomplete="off" autocorrect="off" autofocus="true" class="search-input" name="search"
           placeholder="Search..."
           spellcheck="false" type="search"/>
    <p class="text-muted">
      <small>
        <strong>Hit enter to search or ESC to close</strong>
      </small>
    </p>
  </form>
  <div class="search-suggestions">
    <h6 class="text-uppercase">
      <strong>Suggestions?</strong>
    </h6>
    <p class="text-primary">#medical #analytics #fitness #transport #ui #dashboard #admin #bootstrap #angular
      #typescript</p>
  </div>
  <button (click)="d('Cross click')" aria-label="Close search form" class="search-close" type="button">
    <i class="fi flaticon-close"></i>
  </button>
</ng-template>


<div class="configuration hidden-sm-down" [ngClass]="{'active': showSettings}">
  <div class="configuration-cog" (click)="showSettings = !showSettings">
    <i class="icon icon-arrows-square-left"></i>
    <!--<i class="icon icon-basic-mixer2"></i>-->
  </div>
  <div class="card">
    <div class="card-header">
      Options
    </div>
    <div class="card-block">
      <small class="ff-headers text-uppercase mb-3">
        <strong>Explore Sidebar</strong>
      </small>
      <div class="custom-controls-stacked mb-2">
        <label class="custom-control custom-checkbox">
          <input (change)="isOpened = true; mode = _mode" [(ngModel)]="_mode" class="custom-control-input"
                 name="radio-stacked" type="radio"
                 value="push">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Push mode</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input (change)="isOpened = true; mode = _mode" [(ngModel)]="_mode" class="custom-control-input"
                 name="radio-stacked" type="radio"
                 value="dock">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Docked mode</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input (change)="isOpened = true; mode = _mode" [(ngModel)]="_mode" class="custom-control-input"
                 name="radio-stacked" type="radio"
                 value="over">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Over content mode</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input (change)="isOpened = true; mode = _mode" [(ngModel)]="_mode" class="custom-control-input"
                 name="radio-stacked" type="radio"
                 value="slide">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Slide mode</span>
        </label>
      </div>

      <small class="ff-headers text-uppercase mb-3">
        <strong>Select A Theme</strong>
      </small>
      <div class="custom-controls-stacked mb-2">
        <label class="custom-control custom-checkbox">
          <input [(ngModel)]="theme" class="custom-control-input" name="radio-stacked" type="radio" value="light">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Light theme</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input [(ngModel)]="theme" class="custom-control-input" name="radio-stacked" type="radio" value="dark">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">Dark theme</span>
        </label>
      </div>
    </div>
  </div>
</div>
