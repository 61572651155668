import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BridgeDocument, BridgeListFilter, BridgeModel} from '../../model/bridge.model';
import {BridgeAPI, UnitAPI} from '../backend.api.config';
import {Observable} from 'rxjs';
import {UnitModel} from '../../model/unit.model';

@Injectable()
export class BridgeService {

  constructor(private _http: HttpClient) {
  }


  public saveBridge(bridge: BridgeModel) {
    return this._http.post(BridgeAPI.SAVE, bridge);
  }

  public updateBridge(bridge: BridgeModel) {
    return this._http.post(BridgeAPI.UPDATE, bridge);
  }

  public deleteBridge(bridgeNo) {
    const params = new HttpParams().set('bridgeNo', bridgeNo);
    return this._http.delete(BridgeAPI.GET, {params: params});
  }

  public listUnit(): Observable<UnitModel[]> {
    return this._http.get<UnitModel[]>(UnitAPI.LIST);
  }

  public listBridgeStatus(): Observable<string[]> {
    return this._http.get<string[]>(BridgeAPI.LIST_BRIDGE_STATUS);
  }

  public listBridges(filter: BridgeListFilter): Observable<BridgeModel[]> {
    return this._http.post<BridgeModel[]>(BridgeAPI.LIST, filter);
  }

  public listBridgeNos() {
    return this._http.get(BridgeAPI.LIST_NO);
  }

  public listBridgeNosByStatus(approved, cancelled, pending, inProgress, completed) {
    const params = new HttpParams().set('Approved', approved).set('Cancelled', cancelled)
      .set('Pending', pending).set('In-progress', inProgress).set('Completed', completed);
    return this._http.get(BridgeAPI.LIST_NO_STATUS_WISE, {params: params});
  }

  public getBridge(bridgeNo: String): Observable<BridgeModel> {
    return this._http.get<BridgeModel>(BridgeAPI.GET + '/' + bridgeNo);
  }

  public getProjectAllocatedBridgeCount() {
    return this._http.get(BridgeAPI.GET_ALLOCATED_BRIDGE_COUNT);
  }

  public getBridgeDocument(bridgeNo, documentType): Observable<BridgeDocument> {
    const params = new HttpParams().set('bridge-no', bridgeNo).set('document-type', documentType);
    return this._http.get<BridgeDocument>(BridgeAPI.GET_DOCUMENT, {params: params});
  }

  public updateBridgeDocuments(bridgeDocument: BridgeDocument) {
    return this._http.post(BridgeAPI.UPDATE_ATTACHMENT, bridgeDocument);
  }

  public updateSurveyAndDesign(bridge: BridgeModel) {
    return this._http.post(BridgeAPI.UPDATE_SURVEY_DESIGN, bridge);
  }

  public updateApprovalDrive(bridgeNo, approvalDrive) {
    const params = new HttpParams().set('bridgeNo', bridgeNo).set('approvalDrive', approvalDrive);
    return this._http.post(BridgeAPI.UPDATE_APPROVAL_DRIVE, null, {params});
  }

  public updateApprovalMinistry(bridgeNo, approvalMinistry) {
    const params = new HttpParams().set('bridgeNo', bridgeNo).set('approvalMinistry', approvalMinistry);
    return this._http.post(BridgeAPI.UPDATE_APPROVAL_MINISTRY, null, {params});
  }


  public updateF4status(bridgeNo, status): Observable<BridgeModel> {
    const params = new HttpParams().set('bridgeNo', bridgeNo).set('status', status);
    return this._http.post<BridgeModel>(BridgeAPI.UPDATE_F4_STATUS, null, {params});
  }

  public updateBridgeDocumentDate(bridgeNo, documentType, date): Observable<BridgeModel> {
    const params = new HttpParams().set('bridgeNo', bridgeNo).set('documentType', documentType).set('date', date);
    return this._http.post<BridgeModel>(BridgeAPI.UPDATE_DOCS_DATE, null, {params});
  }
}
