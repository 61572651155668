import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserModel} from '../../model/security/user.model';
import {GroupAPI, UserAPI} from '../backend.api.config';
import {Observable} from 'rxjs';
import {IExchangeUpdateUser} from '../../model/security/user.update.model';
import {ResponseModel} from '../../model/response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  public saveUser(user: UserModel) {
    return this.http.post(UserAPI.SAVE, user);
  }

  public updateUser(exchangeUpdateUser: IExchangeUpdateUser) {
    return this.http.put(UserAPI.UPDATE, exchangeUpdateUser);
  }

  public deleteUser(userId: String) {
    return this.http.delete(UserAPI.DELETE + userId);
  }

  public getUser(userId: String): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(UserAPI.GET + userId);
  }

  public retrieveUserImage(userId: String): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(UserAPI.IMAGE + '/' + userId);
  }

  public activateUser(id: String): Observable<ResponseModel> {
    return this.http.put<ResponseModel>(UserAPI.ENABLE + '/' + id, {});
  }

  public deactivateUser(id: String): Observable<ResponseModel> {
    return this.http.put<ResponseModel>(UserAPI.DISABLE + '/' + id, {});
  }

  public addGroupToTheUser(userId: String, groupId: String): Observable<ResponseModel> {
    return this.http.put<ResponseModel>(UserAPI.ADD_GROUP + '/' + userId + '/' + groupId, {});
  }

  public lockUser(userName: String) {
    return this.http.put(UserAPI.LOCK + '/' + userName, {});
  }

  public unlockUser(userName: String) {
    return this.http.put(UserAPI.UNLOCK + '/' + userName, {});
  }

  public listUsers(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(UserAPI.SYNCED_LIST);
  }

  public updatePassword(userUpdate: IExchangeUpdateUser) {
    return this.http.put(UserAPI.UPDATE_PASSWORD + '/' + userUpdate.userExchange.id, userUpdate);
  }

  public updatePersonalInfo(userUpdate: IExchangeUpdateUser) {
    return this.http.put(UserAPI.UPDATE_PERSONAL_INFO + '/' + userUpdate.userExchange.id, userUpdate);
  }

  public syncUserList(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(UserAPI.SYNC_USER_LIST);
  }

  public listUserGroups(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(GroupAPI.LIST);
  }

  public listUserRolesByGroupId(groupId): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(GroupAPI.LIST_ROLES + '/' + groupId);
  }
}
