import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IExchangeUpdateUser, USER_ATTRIBUTE_TO_UPDATE} from '../model/security/user.update.model';
import {ConfigService} from '../common/service/config.service';
import {TokenService} from '../common/service/token.service';
import Swal from 'sweetalert2';
import {ResponseService} from '../common/service/response.service';
import {Message_Type, SweetAlert} from '../common/sweet.alert';
import {NAVIGATION} from '../common/app.config';
import {Router} from '@angular/router';
import {UserService} from '../common/service/user.service';
import {User_Attribute_Name} from '../model/security/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  updateUserExchange: IExchangeUpdateUser = this.configService.getInstanceOfIExchangeUpdateUser();
  retypedPassword = '';
  passwordPattern = '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[$@$!%*#?&])[A-Za-z\\d$@$!%*#?&]{6,}$';
  loading = false;

  constructor(public activeModal: NgbActiveModal,
              private configService: ConfigService,
              private tokenService: TokenService,
              private router: Router,
              private userService: UserService,
              private responseService: ResponseService) {
  }

  ngOnInit() {
    this.loadUserInfoFromToken();
  }

  async loadUserInfoFromToken() {
    const existingUser = await this.tokenService.getExistingUserExchange();
    this.updateUserExchange.userExchange.firstName = existingUser.firstName;
    this.updateUserExchange.userExchange.lastName = existingUser.lastName;
    this.updateUserExchange.userExchange.username = existingUser.username;
    this.updateUserExchange.userExchange.email = existingUser.email;
    this.updateUserExchange.userExchange.id = existingUser.id;
    this.loadUser();
  }

  loadUser() {
    this.userService.getUser(this.updateUserExchange.userExchange.id).subscribe(
      res => {
        this.loading = false;
        this.updateUserExchange.userExchange.firstName = res.objectAffected.firstName;
        this.updateUserExchange.userExchange.lastName = res.objectAffected.lastName;
        this.updateUserExchange.userExchange.phone = res.objectAffected.attributes[User_Attribute_Name.PHONE];
        this.updateUserExchange.userExchange.attributes = res.objectAffected.attributes;
        this.updateUserExchange.userExchange.enabledCountries = res.objectAffected.enabledCountries;
      },
      err => {
        this.loading = false;
        this.responseService.checkErrorResponse(err);
      }
    );
  }

  savePersonalInformation() {
    if (this.updateUserExchange.userExchange.firstName.trim() === '') {
      SweetAlert.showMessageOnTopCorner(
        'Please enter valid first name!', Message_Type.INFO);
      return;
    }
    if (this.updateUserExchange.userExchange.lastName.trim() === '') {
      SweetAlert.showMessageOnTopCorner(
        'Please enter valid last name!', Message_Type.INFO);
      return;
    }
    if (this.updateUserExchange.userExchange.phone.toString().trim() === '') {
      SweetAlert.showMessageOnTopCorner(
        'Please enter valid phone number!', Message_Type.INFO);
      return;
    }
    Swal.mixin().fire({
      title: 'Are you sure, you want to update?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Update!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.createUserAttributeToUpdate();
        this.updateUserExchange.userAttributesToUpdate = [];
        this.buildUserAttributes(this.updateUserExchange);
        this.loading = true;
        this.userService.updatePersonalInfo(this.updateUserExchange).subscribe(res => {
          this.loading = false;
          // const user: UserModel = res[ResponseService.RESPONSE_OBJECT_AFFECTED];
          // this.tokenService.setUpdatedName(user.firstName + ' ' + user.lastName);
          // TODO: need to update Logged user data
          SweetAlert.showMessageOnTopCorner(res[ResponseService.RESPONSE_MAP][ResponseService.RESPONSE_MESSAGE], Message_Type.SUCCESS);
        }, error => {
          this.loading = false;
          this.responseService.checkErrorResponse(error);
        });
      }
    });

  }

  buildUserAttributes(exchangeUpdateUser: IExchangeUpdateUser) {
    exchangeUpdateUser.userAttributesToUpdate = [USER_ATTRIBUTE_TO_UPDATE.FIRST_NAME,
      USER_ATTRIBUTE_TO_UPDATE.LAST_NAME,
      USER_ATTRIBUTE_TO_UPDATE.USER_ATTRIBUTES];
  }

  private createUserAttributeToUpdate() {
    // TODO: need to validate phone numbers
    this.updateUserExchange.userExchange.attributes[User_Attribute_Name.PHONE] = [this.updateUserExchange.userExchange.phone.toString()];
  }

  changePassword() {
    if (this.updateUserExchange.currentPassword.trim().match(this.passwordPattern) === null) {
      SweetAlert.showMessageOnTopCorner(
        'Current password should contain 6 letters including 1 special character and 1 numeric!', Message_Type.INFO, 4000);
      return;
    }
    if (this.updateUserExchange.userExchange.password.trim().match(this.passwordPattern) === null) {
      SweetAlert.showMessageOnTopCorner(
        'New password should contain 6 letters including 1 special character and 1 numeric!', Message_Type.INFO, 4000);
      return;
    }
    if (this.updateUserExchange.userExchange.password.trim() !== this.retypedPassword.trim()) {
      SweetAlert.showMessageOnTopCorner('Re-typed password does not match!', Message_Type.ERROR);
      return;
    }
    Swal.mixin().fire({
      title: 'Are you sure, you want to update?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Update!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.userService.updatePassword(this.updateUserExchange).subscribe(res => {
          this.loading = false;
          this.activeModal.close();
          SweetAlert.showMessageOnTopCorner(res[ResponseService.RESPONSE_MAP][ResponseService.RESPONSE_MESSAGE] + '\n Please login with new password!', Message_Type.SUCCESS, 4000);
          this.tokenService.logoutAndClearToken();
          this.router.navigateByUrl(NAVIGATION.HOME);
        }, error => {
          this.loading = false;
          this.responseService.checkErrorResponse(error);
        });
      }
    });
  }

  cancelPersonalInformation() {
    this.loadUserInfoFromToken();
  }

  cancelChangePassword() {
    this.updateUserExchange.currentPassword = '';
    this.updateUserExchange.userExchange.password = '';
    this.retypedPassword = '';
  }

}
