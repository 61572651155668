import {SESSION_STORAGE_KEYS} from './app.config';
import {BTN, INDEX} from './ui.component.config';

export class AppCommonMethods {
  public capitalizeFirstLetter(camelCaseWord: string): string {
    if (camelCaseWord.length === 0) {
      return camelCaseWord;
    }
    const separatedWords = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\./g, ' ');
    return separatedWords.charAt(0).toUpperCase() + separatedWords.slice(1);
  }

  public getUIModuleActive(module) {
    const uiComponents = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.UI_COMPONENTS));
    if (uiComponents) {
      return uiComponents.filter(value => value.name === module).some((mod) => mod.active && mod.enableForGivenUser);
    }
  }

  public getUIComponentActive(module, component) {
    const uiComponents = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.UI_COMPONENTS));
    if (uiComponents) {
      return uiComponents
        .filter(value => value.name === module)
        .flatMap(mod => mod.uiComponentExchangeList)
        .some(com => com.name === component && com.active && com.enableForGivenUser);
    }
  }

  public getUIComponents(module, component) {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.UI_COMPONENTS))
      .find(obj => obj.name === module && obj.moduleType === 'WEB')?.uiComponentExchangeList.filter(value => value.name === component) || [];
  }

  public getUIComponentValue(components, section, field): any {
    const selectedField = components
      .map(component => component.uiFieldExchangeList)
      .flat()
      .find(value => value.section === section && value.field === field);
    return selectedField || null;
  }

  public getExcelColumnNames(components, section: string) {
    const columnNames = components
      .flatMap(component => component.uiFieldExchangeList || [])
      .filter(value => value.section === section)
      .map(value => value)
      .sort((a, b) => {
        if (a.backendField === INDEX) {
          return -1;
        } else if (b.backendField === INDEX) {
          return 1;
        } else {
          return 0;
        }
      });
    return columnNames.length > 0 ? columnNames : null;
  }

  public getAllSubsections(components, mainSection) {
    const subsections = Object.values(mainSection);
    const results = components
      .flatMap(component => component.uiFieldExchangeList || [])
      .filter(value => subsections.includes(value.section) && value.active === true)
      .filter(item => !item.field.startsWith(BTN));
    return results;
  }

  public getAllSubsectionButtons(components, mainSection, field) {
    const subsections = Object.values(mainSection);
    const results = components
      .flatMap(component => component.uiFieldExchangeList || [])
      .filter(value => subsections.includes(value.section) && value.active === true)
      .filter(item => item.field.startsWith(BTN) && item.field.endsWith(field));
    return results;
  }

}
