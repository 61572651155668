import {Component, Inject, OnInit} from '@angular/core';
import {AppInfoModel} from './model/app.info';
import {KeycloakService} from 'keycloak-angular';
import {DOCUMENT} from '@angular/common';
import {AppInfoService} from './common/service/app.info.service';
import {environment} from '../environments/environment';
import {LOCAL_STORAGE_KEYS} from './common/app.config';
import {Message_Type, SweetAlert} from './common/sweet.alert';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  public appInfo: AppInfoModel;

  constructor(private keycloak: KeycloakService, @Inject(DOCUMENT) private document: any,
              private appInfoService: AppInfoService,
  ) {
  }

  ngOnInit() {
    const domain = this.document.location.hostname;
    console.log('Domain ', domain);
    this.getAppInfo(domain);
  }

  getAppInfo(host) {
    const resourceURL = environment.RESOURCE_URL.concat('/', host);
    this.appInfoService.getAppInfo(resourceURL.concat('/appInfo.json')).subscribe(
      res => {
        this.appInfo = res;
        localStorage.setItem(LOCAL_STORAGE_KEYS.APP_INFO, JSON.stringify(this.appInfo));
      },
      err => {
        SweetAlert.showMessageOnTopCorner('An error occurred while loading application information.', Message_Type.ERROR);
      }
    );
  }
}
