import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Message_Type, SweetAlert} from '../../sweet.alert';
import {AuthenticationAPI} from '../../backend.api.config';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../../environments/environment';

@Injectable()
export class UnauthorizedResponseInterceptor implements HttpInterceptor {

  private PLEASE_LOGIN = 'Unauthorized Access, Please login!';

  constructor(private router: Router, private keycloakService: KeycloakService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(evt => {
        // return of(evt)
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (!error.url.includes(AuthenticationAPI.SIGNIN)) { // TODO : should remove
            if (error.status === 401) {
              SweetAlert.showMessageOnTopCorner(this.PLEASE_LOGIN, Message_Type.ERROR);
              this.keycloakService.login({
                redirectUri: environment.KEYCLOAK_CONFIG.redirect_uri
              });
            } else if (error.status === 0) {
              SweetAlert.showMessageOnTopCorner(this.PLEASE_LOGIN, Message_Type.ERROR);
              this.keycloakService.logout(environment.KEYCLOAK_CONFIG.logout_uri);
            }
          }

          return next.handle(req);
        }
      })
    );
  }

}
