import {NAVIGATION} from '../../../app.config';
import {BRIDGE_NAVIGATION} from '../../../../bridge/bridge.config';
import {Injectable} from '@angular/core';
import {SUBCONTRACTOR_NAVIGATION} from '../../../../subcontractor/subcontractor.config';
import {Router} from '@angular/router';
import {Message_Type, SweetAlert} from '../../../sweet.alert';
import {USER_NAVIGATION} from '../../../../user/user.config';
import {REPORT_NAVIGATION} from '../../../../report/report.config';
import {ADMIN_MONITOR_NAVIGATION} from '../../../../admin-monitor/admin-monitor.config';

@Injectable()
export class FinalUrls {
  static ERROR_MESSAGE_UNAUTHORIZED_ACCESS = 'Unauthorized Access! Please contact administration';

  static BRIDGE_NEW: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.NEW_BRIDGE;
  static BRIDGE_RENEW: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.RENEW;
  static BRIDGE_RENEW_LIST: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.RENEW_LIST;
  static BRIDGE_PROGRESS: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.PROGRESS;
  static BRIDGE_PROGRESS_NOT_REPORTED: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.PROGRESS_NOT_REPORTED;
  static BRIDGE_ISSUE: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.ISSUE;
  static BRIDGE_LIST: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.LIST;
  static SPAN_MASTER: string = '/' + NAVIGATION.BRIDGE + '/' + BRIDGE_NAVIGATION.SPAN_MASTER;
  static SUBCONTRACTOR_REGISTRATION: string = '/' + NAVIGATION.SUBCONTRACTOR + '/' + SUBCONTRACTOR_NAVIGATION.REGISTRATION;
  static SUBCONTRACTOR_LIST: string = '/' + NAVIGATION.SUBCONTRACTOR + '/' + SUBCONTRACTOR_NAVIGATION.LIST;
  static USER_REGISTRATION: string = '/' + NAVIGATION.USER + '/' + USER_NAVIGATION.REGISTRATION;
  static USER_LIST: string = '/' + NAVIGATION.USER + '/' + USER_NAVIGATION.LIST;
  static BRIDGE_REPORT: string = '/' + NAVIGATION.REPORT + '/' + REPORT_NAVIGATION.BRIDGE_REPORT;
  static MONTHLY_REPORT: string = '/' + NAVIGATION.REPORT + '/' + REPORT_NAVIGATION.MONTHLY_REPORT;
  static MONITOR_END_POINTS: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.MONITOR_END_POINTS;
  static INFO: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.INFO;
  static ENV: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.ENV;
  static AUDIT_EVENTS: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.AUDIT_EVENTS;
  static HEALTH: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.HEALTH;
  static METRICS: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.METRICS;
  static CONFIG_PROPERTIES: string = '/' + NAVIGATION.ADMIN_MONITOR + '/' + ADMIN_MONITOR_NAVIGATION.CONFIG_PROPERTIES;

  static navigateToDashboard(router: Router, url: string): void {
    const message: string = this.ERROR_MESSAGE_UNAUTHORIZED_ACCESS;
    console.log(message + ': ' + url);

    SweetAlert.showMessageOnTopCorner(message, Message_Type.ERROR, 4000);
    router.navigate([NAVIGATION.DASHBOARD]).catch((ex) => {
      console.log(ex);
    });
  }

}
